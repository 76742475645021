import {ReactElement, useState, useEffect, useContext} from "react";
import {UtilsApi, Address, HandoffOptions, AddressBook, ErrorType} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {addError, resetModalCount, updateLastSearchedPlaceId} from "@/redux/meta/metaActions";
import {useNavigate, useParams} from "react-router";
import {BsFillHouseDoorFill} from "react-icons/bs";
import {FaAngleDown} from "react-icons/fa";
import RestaurantAddressAutoPanel from "./RestaurantAddressAutoPanel";
import RestaurantSearchAddressButtonSkeleton from "../../../components/skeletons/RestaurantSearchAddressButtonSkeleton";
import {BrandMapContext} from "@/pages/brandMap/context/BrandMapContext";

interface Props {
    redirect?: boolean;
}

function RestaurantSearchAddressButton(props: Props): ReactElement {
    const history = useNavigate();
    const dispatch = useDispatch();
    const selectedHandoff = useSelector((store: IStore) => store.metaStore.handoff);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    // const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);

    const {placeId: contextPlaceId} = useContext(BrandMapContext);
    const { placeId: paramPlaceId } = useParams<{ placeId: string; }>();
    const placeId = paramPlaceId || contextPlaceId;

    const [
        address,
        setAddress,
    ] = useState<Address>();
    const [
        showRestaurantSearchAddressModal,
        setShowRestaurantSearchAddressModal,
    ] = useState<boolean>(false);
    const [
        isAddressLoading,
        setIsAddressLoading,
    ] = useState<boolean>(false);
    const findSelectedAddressBook = currentUser?.user?.addresses?.find((addressBook) => addressBook.placeId === placeId);

    useEffect(() => {
        getAddress().then();
    }, [placeId]);

    async function getAddress(selectedPlaceId?: string) {
        if (!placeId && !selectedPlaceId) {
            return;
        }
        const newPlaceId = selectedPlaceId
            ? selectedPlaceId
            : placeId;
        setIsAddressLoading(true);
        try {
            const newAddress = await new UtilsApi().getAddressFromPlaceId({
                placeId: newPlaceId,
            });
            if (newAddress.placeId === newPlaceId) {
                setAddress(newAddress);
            } else {
                // If place ID regenerated, redirect to new place ID.
                window.location.href = `/restaurants/search/${newAddress.placeId}`;
            }
        } catch (e) {
            // If place ID unable to be regenerated, redirect to address input page.
            setShowRestaurantSearchAddressModal(true);
        } finally {
            setIsAddressLoading(false);
        }

    }

    async function onAddressSubmit(address: Address | AddressBook): Promise<void> {
        if (selectedHandoff === HandoffOptions.DELIVERY && !address?.line1.trim()) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: "This address is not valid as a delivery destination.",
            }));
            return;
        }

        if (props.redirect !== false) {
            history(`/restaurants/search/${address?.placeId}`);
        } else {
            void getAddress(address.placeId);
            void dispatch(resetModalCount());
        }

        dispatch(updateLastSearchedPlaceId(address.placeId));
        setShowRestaurantSearchAddressModal(false);
    }

    return (
        <div className="restaurant-search-address-button">
            <RestaurantAddressAutoPanel
                isOpen={showRestaurantSearchAddressModal}
                onClose={() => setShowRestaurantSearchAddressModal(false)}
                onAddressSubmit={onAddressSubmit}
                defaultAddressBook={findSelectedAddressBook}
                showToggleDeliveryMode={true}
                modalOnMobile={true}
                modalOnTablet={true}
            />
            {isAddressLoading
                ? <RestaurantSearchAddressButtonSkeleton/>
                : <button
                    className="restaurant-search-address-button_button"
                    onClick={() => setShowRestaurantSearchAddressModal(true)}
                    type="button"
                >
                    <BsFillHouseDoorFill className="restaurant-search-address-button_button_left"/>
                    <span>
                        <span className="restaurant-search-address-button_button_top">
                            {selectedHandoff === HandoffOptions.PICKUP
                                ? "Pickup around"
                                : "Deliver to"}
                        </span>
                        <span className="restaurant-search-address-button_button_address">
                            {address?.line1 && address.line1.trim() !== ""
                                ? address.line1
                                : "Address"}
                        </span>
                    </span>
                    <FaAngleDown className="restaurant-search-address-button_button_right"/>
                </button>
            }

        </div>
    );
}

export default RestaurantSearchAddressButton;
