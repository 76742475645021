import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import OverwolfGoVipAccountLevel from "@/pages/overwolf/components/OverwolfGoVipAccountLevel";
import OverwolfGoVipRewards from "@/pages/overwolf/components/OverwolfGoVipRewards";
import FrameButton from "@/components/buttons/FrameButton";
import {useEffect, useState} from "react";
import MagicLoginModal from "@/components/modals/MagicLoginModal";
import {toggleSidebar} from "@/redux/meta/metaActions";
import OverwolfIndustryPassPromoBanner from "@/pages/overwolf/components/OverwolfIndustryPassPromoBanner";
import useGetOverwolfArt, {OverwolfArtType} from "@/hooks/useGetOverwolfArt";
import OverwolfGameLogo from "./components/OverwolfGameLogo";
import {ThemePreference} from "@/types/Theme";
import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import OverwolfDiscordBanner from "@/pages/overwolf/components/OverwolfDiscordBanner";
import OWAnnouncementModal from "@/components/devourPlay/OWAnnouncementModal";
import { OverwolfGoVipSummary } from "./components/OverwolfGoVipSummary";
import classNames from "classnames";

export default function OverwolfGoVip() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const { getOverwolfArt} = useGetOverwolfArt(OverwolfArtType.bannerImage);
    const dispatch = useDispatch();
    const {setTheme} = useThemePreference();
    const [isAnnouncementModalOpen, setIsAnnouncementModalOpen] = useState(false); // TODO: Set this if you want to show feature announcement modal

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
        const originalTheme = store.getState().metaStore.themePreference;
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    return (
        <>
            <OWAnnouncementModal
                isOpen={isAnnouncementModalOpen}
                onClose={() => setIsAnnouncementModalOpen(false)}
                version={"1.1.0"}
                title={"This Is The Title Of The Announcement! 🔥🔥🔥"}
                link={"https://devourgo.io/"}
                description={"This should be more detailed description of the announcement. 💜 Describe what this new feature is about, how to utilize it, and how amazing it is. Let’s make sure to keep under 5 sentences in total, because we don’t want to have a huge paragraph, that users might not even read. 🚀"}
                image={`${import.meta.env.VITE_CDN_URL}/images/OW-hotkey-lg.webp`}
            />

            <div
                className="overwolf-govip"
                style={{
                    backgroundImage: `url(${getOverwolfArt})`,
                }}
            >
                <MagicLoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>

                <div className="overwolf-govip_content">
                    <div className="overwolf-govip_header">
                        <OverwolfGameLogo/>
                        <h1>PLAY TO EARN</h1>
                    </div>
                    <div className={classNames({
                        "overwolf-govip_content_summary": !!fullToken,
                    })}>
                        <OverwolfGoVipAccountLevel/>
                        { fullToken && <OverwolfGoVipSummary/>}
                    </div>

                    <OverwolfIndustryPassPromoBanner/>

                    <OverwolfGoVipRewards/>
                </div>

                {!fullToken && <FrameButton
                    size="large"
                    className="overwolf-govip_login-btn overwolf-govip_button_purple-blue-gradient-shadow"
                    onClick={() => setIsLoginModalOpen(true)}
                >
                    Login or Sign up
                </FrameButton>
                }
            </div>
            {fullToken && <OverwolfDiscordBanner />}
        </>

    );
}
